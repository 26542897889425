<template>
  <div class="smart-ar-glasses">
    <div class="wrap-content">
      <div class="top-title">
        <div class="left">
          <img :src="navImg" alt="" />
          <span>已连接</span>
        </div>
        <div class="search">
          <img :src="navImg3" alt="" />
          <span>搜索</span>
        </div>
        <div class="right">
          <img :src="navImg2" alt="" />
        </div>
      </div>
      <div class="content-box hide-scrollbar">
        <div
          class="images-box"
          v-for="(item, index) in imagesList"
          :key="'img' + index"
        >
          <img :src="item" alt="" />
        </div>
      </div>

      <div class="footer">
        <tabbarBox :tabbarData="tabbarData" :tabbarNum="5"></tabbarBox>
      </div>
    </div>
  </div>
</template>

<script setup>
import tabbarBox from "../tabbar/index.vue";
// 顶部盒子图片
const navImg = require("@/assets/images/imitatepage/smart-ar-glasses/icon_nav.png");
const navImg2 = require("@/assets/images/imitatepage/smart-ar-glasses/icon_nav2.png");
const navImg3 = require("@/assets/images/imitatepage/smart-ar-glasses/icon_nav3.png");

//图片列表
const imagesList = [
  require("@/assets/images/imitatepage/smart-ar-glasses/card-1.png"),
  require("@/assets/images/imitatepage/smart-ar-glasses/card-2.png"),
  require("@/assets/images/imitatepage/smart-ar-glasses/card-3.png"),
  require("@/assets/images/imitatepage/smart-ar-glasses/card-4.png"),
  require("@/assets/images/imitatepage/smart-ar-glasses/card-5.png"),
  require("@/assets/images/imitatepage/smart-ar-glasses/card-6.png"),
];

// tabbar
const tabbarData = [
  {
    icon: require("@/assets/images/imitatepage/smart-ar-glasses/tabbar/icon_tab.png"),
    text: "首页",
    color: "#102f4b",
    isConvention: true,
    bold: 600,
  },
  {
    icon: require("@/assets/images/imitatepage/smart-ar-glasses/tabbar/icon_tab2.png"),
    text: "检修",
    color: "#999999",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/smart-ar-glasses/tabbar/icon_tab3.png"),
    text: "通讯",
    color: "#999999",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/smart-ar-glasses/tabbar/icon_tab4.png"),
    text: "录像",
    color: "#999999",
    isConvention: true,
  },
  {
    icon: require("@/assets/images/imitatepage/smart-ar-glasses/tabbar/icon_tab5.png"),
    text: "我的",
    color: "#999999",
    isConvention: true,
  },
];
</script>

<style lang="scss" scoped>
.smart-ar-glasses {
  position: relative;
  width: 100%;
  height: 100%;

  .wrap-content {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    // padding-bottom: 60px;
    box-sizing: border-box;
    // padding: 16px;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 0 2px;

    .top-title {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      margin-top: 55px;
      padding: 0 16px;
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #0097e0;
        font-size: 10px;
        img {
          width: 28px;
          height: 14px;
          margin-bottom: 1px;
        }
      }
      .search {
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 15px;
        background: #f5f5f7;
        padding-left: 13px;
        box-sizing: border-box;
        line-height: 30px;
        margin: 0 13px;
        color: #8e9091;
        font-size: 15px;
        img {
          width: 16px;
          height: 15px;
          margin-right: 6px;
        }
      }
      .right {
        display: flex;
        img {
          width: 23px;
          height: 22px;
        }
      }
    }
    .content-box {
      //   height: calc(100% - 5px);
      overflow-y: auto;
      margin-top: 18px;
      padding: 0px 16px 30px;
      box-sizing: border-box;
      height: 100%;

      .images-box {
        margin-bottom: 15px;
        height: 160px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // tabbar
    .footer {
      position: sticky;
      bottom: 0;
      width: 100%;
      box-shadow: 2px 0px 8px 1px #e4e3e3;
      box-sizing: border-box;
      z-index: 2;
    }
  }
}
</style>
